var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Awal","label-for":"start_date"}},[_c('validation-provider',{attrs:{"name":"Tanggal Awal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"start_date","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Awal"},model:{value:(_vm.dataParent.start_date),callback:function ($$v) {_vm.$set(_vm.dataParent, "start_date", $$v)},expression:"dataParent.start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal Akhir","label-for":"end_date"}},[_c('validation-provider',{attrs:{"name":"Tanggal Akhir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"end_date","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Akhir"},model:{value:(_vm.dataParent.end_date),callback:function ($$v) {_vm.$set(_vm.dataParent, "end_date", $$v)},expression:"dataParent.end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Tahun Akademik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tahun","label-for":"academic_year_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"academic_year_id","reduce":function (academic_year_id) { return academic_year_id.real_id; },"placeholder":"Pilih Tahun","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listacademicyear,"label":"name"},model:{value:(_vm.dataParent.academic_year_id),callback:function ($$v) {_vm.$set(_vm.dataParent, "academic_year_id", $$v)},expression:"dataParent.academic_year_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6","sm":"6"}},[_c('validation-provider',{attrs:{"name":"level"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tingkat","label-for":"level","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"level","reduce":function (level) { return level.real_id; },"placeholder":"Pilih Tingkat","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listclass,"label":"level_desc"},model:{value:(_vm.dataParent.level),callback:function ($$v) {_vm.$set(_vm.dataParent, "level", $$v)},expression:"dataParent.level"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Potongan","label-for":"potongan"}},[_c('validation-provider',{attrs:{"name":"potongan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"potongan","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Potongan"},model:{value:(_vm.dataParent.potongan),callback:function ($$v) {_vm.$set(_vm.dataParent, "potongan", $$v)},expression:"dataParent.potongan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.GetAllData}},[_c('feather-icon',{attrs:{"icon":"ZoomInIcon"}}),_vm._v(" View ")],1),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"gradient-info"},on:{"click":_vm.validationForm}},[_c('feather-icon',{attrs:{"icon":"DatabaseIcon"}}),_vm._v(" Generate ")],1)],1)],1)],1)],1)],1)],1),_c('table',{staticClass:"table",attrs:{"id":"generatetable"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("No")]),_c('th',[_vm._v("NISN")]),_c('th',[_vm._v("Nama")]),_c('th',[_vm._v("Kelas")]),_c('th',[_c('input',{attrs:{"type":"checkbox","name":"select_all","value":"1","id":"example-select-all"}})])])]),_c('tbody')])],1)}
var staticRenderFns = []

export { render, staticRenderFns }